import { Inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AppType, ENVIRONMENT, Environment, Partner } from '@domains';
import { Observable, map, of, take } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

@Injectable({ providedIn: 'root' })
export class PartnerFeatureGuard implements CanActivate {
  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
    private authService: AuthenticationService,
    @Inject(ENVIRONMENT) private config: Environment
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const features = route.data['features'] as Array<string>;
    const allowed = route.data['shouldAllowFeatures'] as boolean;
    return this.authService.authData$.pipe(
      take(1),
      switchMap((authData) => {
        if (authData) {
          if (this.config.app === AppType.TPL) {
            return this.authService
              .getPartner()
              .pipe(map((partner) => this.canPass(features, allowed, partner)));
          }
          return of(true);
        } else {
          return of(false);
        }
      })
    );
  }

  canPass(features: Array<string>, allowed: boolean, p?: Partner): boolean {
    for (const feature of features) {
      if (!!((p || {}) as any)[feature] === allowed) {
        return true;
      }
    }
    this.snackBar.open('Access Not Allowed', 'x', {
      duration: 5000,
    });
    this.router.navigate(this.config.defaultRoute);
    return false;
  }
}
