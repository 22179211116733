<ng-template #IconButton let-element="element" let-action="action">
  <button
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [disabled]="!!action.disabled && action.disabled(element)"
    [color]="'primary'"
    mat-icon-button
    mat-mini-fab
    [matTooltipShowDelay]="1000"
    [matTooltip]="action.actionTitle"
    (click)="action.actionMethod ? action.actionMethod(element) : null"
    type="button"
  >
    <mat-icon [svgIcon]="action.icon.name">{{ action.icon }}</mat-icon>
  </button>
</ng-template>
<ng-template #IconLink let-element="element" let-action="action">
  <a
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [disabled]="!!action.disabled && action.disabled(element)"
    [color]="'primary'"
    mat-icon-button
    mat-mini-fab
    [matTooltipShowDelay]="1000"
    [matTooltip]="action.actionTitle"
    [routerLink]="action.link ? action.link(element) : null"
    [queryParams]="action.queryParams ? action.queryParams(element) : null"
  >
    <mat-icon [svgIcon]="action.icon.name">{{ action.icon }}</mat-icon>
  </a>
</ng-template>
<ng-template #ExternalIconLink let-element="element" let-action="action">
  <a
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [disabled]="!!action.disabled && action.disabled(element)"
    [color]="'primary'"
    mat-icon-button
    mat-mini-fab
    [matTooltipShowDelay]="1000"
    [matTooltip]="action.actionTitle"
    [href]="action.externalLink(element)"
    target="_blank"
  >
    <mat-icon [svgIcon]="action.icon.name">{{ action.icon }}</mat-icon>
  </a>
</ng-template>
<ng-template #TextButton let-element="element" let-action="action">
  <button
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [disabled]="!!action.disabled && action.disabled(element)"
    mat-raised-button
    [matTooltip]="action.actionTitle"
    [matTooltipShowDelay]="1000"
    (click)="action.actionMethod ? action.actionMethod(element) : null"
    type="button"
  >
    <span>{{ action.actionTitle }}</span>
  </button>
</ng-template>
<ng-template #TextLink let-element="element" let-action="action">
  <a
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [disabled]="!!action.disabled && action.disabled(element)"
    mat-raised-button
    [matTooltip]="action.actionTitle"
    [matTooltipShowDelay]="1000"
    [routerLink]="action.link ? action.link(element) : null"
    [queryParams]="action.queryParams ? action.queryParams(element) : null"
  >
    <span>{{ action.actionTitle }}</span>
  </a>
</ng-template>
<ng-template #ExternalTextLink let-element="element" let-action="action">
  <a
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [disabled]="!!action.disabled && action.disabled(element)"
    mat-raised-button
    [matTooltip]="action.actionTitle"
    [matTooltipShowDelay]="1000"
    [href]="action.externalLink(element)"
    target="_blank"
  >
    <span>{{ action.actionTitle }}</span>
  </a>
</ng-template>
<ng-template #DropdownButton let-element="element" let-action="action">
  <button
    mat-menu-item
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    (click)="action.actionMethod ? action.actionMethod(element) : null"
    [disabled]="!!action.disabled && action.disabled(element)"
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
    type="button"
  >
    {{ action.actionTitle }}
  </button>
</ng-template>
<ng-template #DropdownLink let-element="element" let-action="action">
  <a
    mat-menu-item
    *ngIf="!!element && (!action.hidden || !action.hidden(element))"
    [routerLink]="action.link ? action.link(element) : null"
    [queryParams]="action.queryParams ? action.queryParams(element) : null"
    [disabled]="!!action.disabled && action.disabled(element)"
    class="{{ action.cssClass ? action.cssClass(element) : '' }} {{action.code}}"
  >
    {{ action.actionTitle }}
  </a>
</ng-template>
<div class="table-wrapper" [ngClass]="{ 'edit-mode': editMode }">
  <ng-content></ng-content>
  <div class="edit-mode-scroll">
    <button
      mat-icon-button
      class="edit-mode-scroll left"
      (click)="scrollHorizontal('left')"
    >
      <mat-icon>arrow_back_ios</mat-icon>
    </button>
    <button
      mat-icon-button
      class="edit-mode-scroll right"
      (click)="scrollHorizontal('right')"
    >
      <mat-icon>arrow_forward_ios</mat-icon>
    </button>
  </div>
  <div
    #tableContainer
    *ngIf="pagedDataSource"
    class="table-container"
    [ngStyle]="{
      'min-height.px': config.calculateHeight && !editMode ? minHeight : 0,
      'max-height.px': editMode ? 146 : undefined,
      'overflow-y': editMode ? 'hidden' : undefined
    }"
    [ngClass]="{ 'has-filters': config.hasFilters || !!filters }"
  >
    <div *ngIf="isLoading" class="table-message">
      <p #loadingIndicator fxLayoutAlign="center">
        <span class="loading">Loading</span>
      </p>
    </div>
    <div
      *ngIf="!isLoading && !!pagedDataSource && !hasData"
      class="table-message"
    >
      <p fxLayoutAlign="center">
        No records
        {{ filters?.isEmpty ? '' : 'have been found for chosen filters' }}
      </p>
      <ng-container
        ngIf="noRecordsTemplate"
        *ngTemplateOutlet="noRecordsTemplate"
      ></ng-container>
    </div>
    <table
      #table
      mat-table
      matSort
      #MatSort="matSort"
      [dataSource]="pagedDataSource"
      class="full-width"
      [ngClass]="{
        'edit-mode': editMode,
        disabled: globalEditMode && !editMode
      }"
      matTableExporter
      #exporter="matTableExporter"
      cdkDropList
      cdkDropListLockAxis="x"
      cdkDropListOrientation="horizontal"
      [matSortDisabled]="!!config.localFilter || !!editMode"
      [matSortDirection]="config.sort?.direction || 'desc'"
      [matSortActive]="config.sort?.field || 'created_at'"
      (cdkDropListDropped)="dropListDropped($event)"
      multiTemplateDataRows
    >
      <!-- Columns -->
      <ng-container
        *ngFor="let column of columns; let colIndex = index"
        [matColumnDef]="column.field"
      >
        <th
          mat-header-cell
          mat-sort-header
          [disableClear]="true"
          [disabled]="!config.columns[column.field].sortable"
          *matHeaderCellDef
          cdkDrag
          [cdkDragDisabled]="
            !config.configure ||
            !editMode ||
            !config.columns[column.field].editable
          "
          [cdkDragData]="{ name: column.field, columIndex: colIndex }"
          class="{{ editMode ? '' : config.columns[column.field].class }} {{
            column.field
          }} {{ config.columns[column.field].type }}-type {{
            config.columns[column.field].editable ? '' : 'not-'
          }}editable"
        >
          <ng-container>
            <p
              class="table-header"
              [ngClass]="{
                'has-info':
                  (config.columns[column.field].description?.length || 0) > 0
              }"
            >
              {{
                config.columns[column.field].hideTitle && !editMode
                  ? ''
                  : config.columns[column.field].title
              }}
              <mat-icon
                *ngIf="
                  (config.columns[column.field].description?.length || 0) > 0 &&
                  !editMode
                "
                onclick="event.stopPropagation()"
                >info
              </mat-icon>
              <span
                *ngIf="
                  (config.columns[column.field].description?.length || 0) > 0 &&
                  !editMode
                "
                [innerHTML]="config.columns[column.field].description"
              ></span>
            </p>
          </ng-container>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="{{ config.columns[column.field].class }} {{ column.field }} {{
            config.columns[column.field].type
          }}-type"
        >
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.STRING"
          >
            {{ getValue(element, column.field) }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.NUMBER"
          >
            {{ getValue(element, column.field) | number : '1.0-0' }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.PRICE"
          >
            {{ (getValue(element, column.field) | currency) || '/' }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.DECIMAL"
          >
            {{ getValue(element, column.field) | number : '1.0-2' }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.DATE"
          >
            {{ getValue(element, column.field) | date : 'MMM d, y' }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.DATE_TIME"
          >
            {{ getValue(element, column.field) | date : 'MMM d, y hh:mm a' }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.BOOLEAN"
          >
            {{ getValue(element, column.field) ? 'Yes' : 'No' }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.HTML"
          >
            <div [innerHTML]="getValue(element, column.field)"></div>
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.PHONE"
          >
            {{ getPhoneValue(element, column.field) }}
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.LINK"
          >
            <a
              [routerLink]="config.columns[column.field].link?.(element)"
              class="underlined"
              >{{ getValue(element, column.field) }}</a
            >
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.TOGGLE"
          >
            <mat-slide-toggle
              #daySlideToggle
              labelPosition="after"
              [checked]="toggleValue(config.columns[column.field], element)"
              (change)="
                onToggleChange(
                  config.columns[column.field],
                  $event.checked,
                  element
                )
              "
              [title]="config.columns[column.field].title"
              [disabled]="disabled(config.columns[column.field], element)"
            ></mat-slide-toggle>
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.SELECT"
          >
            <mat-select 
              [value]="selectValue(config.columns[column.field], element)"
              [placeholder]="config.columns[column.field].selectConfig?.placeholder || ''"
              (selectionChange)="
                onSelectChange(
                  config.columns[column.field],
                  $event.value,
                  element
                )
              "
              >
              <mat-option 
                *ngFor="let option of config.columns[column.field].selectConfig?.options" 
                [value]="option.value">
                {{option.text}}
              </mat-option>
            </mat-select>
          </ng-container>
          <ng-container
            *ngIf="config.columns[column.field].type === columnTypes.TEMPLATE"
          >
            <ng-container
              *ngIf="config.columns[column.field].template"
              [ngTemplateOutlet]="
                columnTemplates
                  ? columnTemplates[config.columns[column.field].template || '']
                  : null
              "
              [ngTemplateOutletContext]="templateContext(config.columns[column.field], element)"
            ></ng-container>
          </ng-container>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="actions" [stickyEnd]="true">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="transparent"
          [ngClass]="{ 'edit-mode': editMode }"
        ></th>
        <td
          mat-cell
          *matCellDef="let element"
          class="action-link"
          [ngClass]="{ 'edit-mode': editMode }"
          [ngStyle]="{
            'min-width.px':
              getVisibleColumnIconActions(element).length +
              getVisibleColumnTextActions(element).length
          }"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="end"
            fxFlex
            *ngIf="
              (config?.columnActions?.length || 0) > 0 &&
              !editMode &&
              ((windowSize !== windowSizes.XS &&
                getVisibleColumnActions(element).length > 0) ||
                (windowSize === windowSizes.XS &&
                  getVisibleColumnActions(element).length === 1))
            "
          >
            <ng-container
              *ngFor="let action of getVisibleColumnIconActions(element)"
              [ngTemplateOutlet]="
                action.link
                  ? IconLink
                  : action.externalLink
                  ? ExternalIconLink
                  : IconButton
              "
              [ngTemplateOutletContext]="{ element: element, action: action }"
            ></ng-container>
            <ng-container
              *ngFor="let action of getVisibleColumnTextActions(element)"
              [ngTemplateOutlet]="
                action.link
                  ? TextLink
                  : action.externalLink
                  ? ExternalTextLink
                  : TextButton
              "
              [ngTemplateOutletContext]="{ element: element, action: action }"
            ></ng-container>
          </div>
          <div
            fxLayout="row"
            fxLayoutAlign="end"
            *ngIf="
              windowSize === windowSizes.XS &&
              !editMode &&
              getVisibleColumnActions(element).length > 1
            "
          >
            <button
              mat-icon-button
              *ngIf="(config?.columnActions?.length || 0) > 0"
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <ng-container
                *ngFor="let action of getVisibleColumnActions(element)"
                [ngTemplateOutlet]="action.link ? DropdownLink : DropdownButton"
                [ngTemplateOutletContext]="{
                  element: element,
                  action: action
                }"
              ></ng-container>
            </mat-menu>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="displayedColumns.length"
        >
          <div
            [class.expandable-element-detail]="config.expandable"
            [@detailExpand]="
              element == expandedElement ? 'expanded' : 'collapsed'
            "
            fxLayout="row"
          >
            <div
              fxFlex="100"
              *ngIf="element == expandedElement && expandTemplate"
            >
              <ng-container
                *ngTemplateOutlet="
                  expandTemplate;
                  context: { element: element }
                "
              ></ng-container>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Expand Column -->
      <ng-container matColumnDef="expand">
        <th
          mat-header-cell
          *matHeaderCellDef
          aria-label="row actions"
          class="expand"
        >
          &nbsp;
        </th>
        <td mat-cell *matCellDef="let element" class="expand">
          <button
            mat-icon-button
            aria-label="expand row"
            (click)="
              expandedElement = expandedElement === element ? null : element;
              $event.stopPropagation()
            "
          >
            <mat-icon *ngIf="expandedElement !== element"
              >keyboard_arrow_down</mat-icon
            >
            <mat-icon *ngIf="expandedElement === element"
              >keyboard_arrow_up</mat-icon
            >
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let element; columns: displayedColumns"
        (rsplDoubleClick)="
          config.doubleClick && filters?.form?.get('inactive')?.value !== true
            ? config.doubleClick(element)
            : null
        "
        [class.expandable-element-row]="config.expandable"
        [class.expandable-expanded-row]="expandedElement === element"
      ></tr>
      <ng-container *ngIf="config.expandable">
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedDetail']"
          [class.expandable-detail-row]="config.expandable"
        ></tr>
      </ng-container>
    </table>
  </div>
  <mat-paginator
    [disabled]="editMode"
    [ngClass]="{ hidden: config.localFilter }"
    class="mat-paginator-sticky"
    [length]="resultsCount"
    [pageSize]="pageSize"
    [pageSizeOptions]="config.pageSizes || []"
    showFirstLastButtons
    [ngClass]="{ hidden: !config.showPagination }"
  >
  </mat-paginator>
</div>
