<ng-template #Button let-action="action">
  <button mat-raised-button [ngClass]="{'hidden' : !!action.hidden && !!action.hidden()}" class="{{action.cssClass? action.cssClass() : ''}}"
          (click)="action.actionMethod()"
          [disabled]="!!action.disabled && !!action.disabled()">{{action.actionTitle}}</button>
</ng-template>
<ng-template #Link let-action="action">
  <a mat-raised-button [ngClass]="{'hidden' : !!action.hidden && !!action.hidden()}" class="{{action.cssClass? action.cssClass() : ''}}"
     [routerLink]="action.link" [disabled]="!!action.disabled && !!action.disabled()">{{action.actionTitle}}</a>
</ng-template>
<ng-template #ExternalLink let-action="action">
  <a mat-raised-button [ngClass]="{'hidden' : !!action.hidden && !!action.hidden()}" class="{{action.cssClass? action.cssClass() : ''}}"
     [href]="action.externalLink" target="_blank" [disabled]="!!action.disabled && !!action.disabled()">{{action.actionTitle}}</a>
</ng-template>
<ng-template #DropdownButton let-action="action">
  <button mat-menu-item [ngClass]="{'hidden' : !!action.hidden && !!action.hidden()}"
          (click)="action.actionMethod()"
          [disabled]="!!action.disabled && !!action.disabled()" class="{{action.cssClass? action.cssClass() : ''}}">
    {{action.actionTitle}}
  </button>
</ng-template>
<ng-template #DropdownLink let-action="action">
  <a mat-menu-item [ngClass]="{'hidden' : !!action.hidden && !!action.hidden()}"
     [routerLink]="action.link"
     [disabled]="!!action.disabled && !!action.disabled()" class="{{action.cssClass? action.cssClass() : ''}}">
    {{action.actionTitle}}
  </a>
</ng-template>
<div class="container-wrapper" fxLayout="row" fxLayoutAlign="space-between"
     *ngIf="!page && (pageTitle || subtitle || visibleActionsCnt > 0)">
  <div class="page-title-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <button class="back-button" mat-icon-button (click)="back()" *ngIf="hasBackButton" [disabled]="editMode">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <div class="title">
      <h1 [innerHTML]="pageTitle"></h1>
      <p>{{subtitle}}</p>
    </div>
  </div>
  <div class="actions" *ngIf="!smallWindowSizes.includes(windowSize)" fxLayout="row wrap" fxLayoutAlign="end">
    <ng-container *ngFor="let action of actions" [ngTemplateOutlet]="!!action.link ? Link : !!action.externalLink ? ExternalLink : Button"
                  [ngTemplateOutletContext]="{action: action}"></ng-container>
  </div>
  <div class="actions" fxLayoutGap="10px" *ngIf="smallWindowSizes.includes(windowSize) && visibleActionsCnt > 0">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <ng-container *ngFor="let action of actions" [ngTemplateOutlet]="!!action.link ? DropdownLink: DropdownButton"
                    [ngTemplateOutletContext]="{action: action}"></ng-container>
    </mat-menu>
  </div>
</div>
<div class="page-content">
  <ng-content fxFlex></ng-content>
</div>
