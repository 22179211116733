import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import {
  MatSlideToggleModule
} from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { RsplApiModule } from '@rspl-api';
import { RsplUIModule } from '@rspl-ui';
import {
  MatTableExporterDirective,
  MatTableExporterModule,
} from 'mat-table-exporter';
import { MapService } from 'ngx-mapbox-gl';
import { ColumnConfigComponent } from './config/column-config.component';
import { GridExportComponent } from './export/grid-export.component';
import { DefaultFilterComponent } from './filter/default-filter.component';
import { TableComponent } from './table/table.component';

@NgModule({
  declarations: [
    TableComponent,
    DefaultFilterComponent,
    GridExportComponent,
    ColumnConfigComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    MatTableExporterModule,
    MatButtonModule,
    MatIconModule,
    FlexLayoutModule,
    MatDialogModule,
    RsplApiModule,
    RsplUIModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatExpansionModule,
    MatTooltipModule,
    RouterModule,
    MatMenuModule,
    DragDropModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    FlexLayoutModule
  ],
  exports: [
    TableComponent,
    DefaultFilterComponent,
    GridExportComponent,
    ColumnConfigComponent,
    MatTableExporterDirective,
  ],
  providers: [],
})
export class RsplTableModule {
  public static forRoot(
  ): ModuleWithProviders<RsplTableModule> {
    return {
      ngModule: RsplTableModule,
      providers: [
        RsplTableModule,
        MapService,
      ],
    };
  }
}
